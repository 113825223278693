const initialState = {
  isActive: false
};

const navBar = (state = initialState, action) => {
  switch (action.type) {
    case 'LEAVE_NAVBAR':
      return {
        ...state,
        isActive: false
      };
      case 'OPEN_NAVBAR':
        return {
          ...state,
          isActive: true
        };
    default:
      return state;
  }
}

export default navBar;
