import { toast } from 'react-toastify';

const Toast = (message) => {
    toast.error(message, {
        className: "h3 alert alert-dismissible alert-danger",
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        rtl: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}

export default Toast;