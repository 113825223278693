import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class ShortGites extends Component {
  // componentDidMount() {
  //   window.scrollTo(0, 0)
  // }


  render() {
    return (
      <>
        <div className="container pb-4 bg-dark border-inner p-4">
          <div className="section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
            <h2 className="text-primary font-secondary">Nos logements</h2>
            <h3 className="display-4 text-uppercase lumiere">A partir de 50€</h3>
          </div>
          <div className='border-0'>
            <div className='row g-4 justify-content-around col-lg-12'>
              {Object.entries(this.props.gites.gites).map(([_, tg]) => {
                return (
                  <div key={"tg_" + tg.id} className='align-items-md-center col-sm-6'>
                    <Link to={"/hebergement-a-marie-galante/" + tg.nom.replace(/\W/g, '-').toLowerCase()}>
                      <div className="container ">
                        <div className="d-flex border border-warning border-5">
                          <div className="">
                            <picture>
                              <source srcset={"/" + tg.image_min + ".avif"} type="image/avif" />
                              <img loading="lazy" className="img-fluid" src={"/" + tg.image_min + ".jpg"} alt={"Hebergement: " + tg.nom} style={{ width: '150px', height: '85px', objectFit: 'cover' }} />
                            </picture>
                            <h4 className="bg-light text-primary m-0 text-center">{tg.tarif}€</h4>
                          </div>
                          <div
                            className="d-flex flex-column text-center justify-content-center text-start bg-secondary border-inner w-100">
                            <h5 className="text-uppercase">{tg.nom}</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    popup: state.popup,
    home: state.home,
    gites: state.gites,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeStatus: () => {
      dispatch({ type: 'view' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShortGites);
