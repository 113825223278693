import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import Logo from '../img/logo.png';


class NavBar extends Component {

    render() {
        const nabBarActive = this.props.bar.isActive;
        return (
            <nav id="allMenu" className="navbar navbar-expand-lg bg-dark navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-0">
                <div className='container-fluid justify-content-end' style={{ maxWidth: "600px" }}>
                    <h2 className="m-0 text-uppercase text-white fs-2" >
                        <img alt="vacansosoleil" src={Logo} atl="Logo vacansosoleil" className="fs-1 text-primary me-1 lumiere" style={{ height: 50, width: 50 }}>
                        </img>
                        Vacansosoleil
                    </h2>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto mx-lg-auto py-0">
                        <NavLink to="/">
                            {
                                    <span className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}
                                        data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Accueil</span>
                                }
                        </NavLink>
                        <div className="nav-item dropdown">
                            <NavLink to="/hebergement-a-marie-galante/" className="lumiere nav-link dropdown-toggle" data-bs-toggle="dropdown">Nos gîtes</NavLink>
                            <div className="dropdown-menu m-0">
                                {Object.entries(this.props.gites.gites).map(([initial, gite]) => {
                                    const g = this.props.gites.gites[initial]
                                    return (
                                        <NavLink key={'gite_' + gite.id}
                                            to={"/hebergement-a-marie-galante/" + g.nom.replace(/\W/g, '-').toLowerCase()}>
                                            {
                                                    <span className={'dropdown-item ' + (this.props.bar.isActive ? "active" : "")}
                                                        data-bs-toggle="collapse" data-bs-target="#navbarCollapse">{gite.nom}</span>
                                                }
                                        </NavLink>
                                    )
                                })}
                            </div>
                        </div>
                        <NavLink to="/nos-tarifs">
                            {
                                <span className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}
                                    data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Tarifs</span>
                            }
                        </NavLink>

                        <NavLink to="/nos-videos">
                            {
                                    <span className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}
                                        data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Vidéos</span>
                            }
                        </NavLink>
                        <NavLink to="/contact">
                            {
                                    <span className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}
                                        data-bs-toggle="collapse" data-bs-target="#navbarCollapse">Contact</span>
                            }
                        </NavLink>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    return {
        gites: state.gites,
        bar: state.bar,
    }
}

export default connect(mapStateToProps)(NavBar);