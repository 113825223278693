import { connect } from 'react-redux';
import { Routes, Route, Link, Outlet, Navigate } from 'react-router-dom';
import PageAccueil from './components/PageAccueil';
import PageNosGites from './components/PageNosGites';
import PageNosVideos from './components/PageNosVideos';
import PageTarifs from './components/PageTarifs';
import NavBar from './components/NavBar';
import NavBar2 from './components/NavBar2';
import FloatingActionButtons from './components/FloatingActionButtons';
import { Component } from 'react';
import Footer from './components/Footer';
import PageContact from './components/PageContact';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "rsuite/dist/rsuite.css";

import './css/cdnjs.cloudflare.com_ajax_libs_bootstrap_5.2.1_css_bootstrap.min.css';
import './css/cake_bootstrap.min.css';
import './css/floatingActionBtn.css';
import { Redirect } from './components/302';

import { ToastContainer } from 'react-toastify';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };

  }

  render() {
    return (
      <div className="App">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {Object.entries(this.props.gites.gites).map(([initial, gi]) => (
          <Link key={'link_' + gi.id} to={'/hebergement-a-marie-galante/' + gi.url_folder} />
        ))}
        <Link key='tarifs' to='/nos-tarifs' />
        <Link key='videos' to='/nos-videos' />
        <Link key='contact' to='/contact' />
        
        <><NavBar2></NavBar2> <FloatingActionButtons /> </>
        <Routes>
          <Route path="/" element={<><PageAccueil /> </>}></Route>
          {Object.entries(this.props.gites.gites).map(([initial, gite]) => {
            return (
              <Route index key={'route_' + gite.id} path={"/hebergement-a-marie-galante/" + gite.nom.replace(/\W/g, '-').toLowerCase()} element={<><PageNosGites initial={initial} /> <Outlet /></>}></Route>
            )
          })}
          <Route exact path='/hebergement-a-marie-galante/les-joyeux-saphir' element={<Navigate to="/hebergement-a-marie-galante/le-joyeux-saphir" replace />} />
          <Route index path='/nos-tarifs' element={<><PageTarifs /> <Outlet /></>}></Route>
          <Route index path='/nos-videos' element={<><PageNosVideos /> <Outlet /></>}></Route>
          <Route index path='/contact' element={<><PageContact /> <Outlet /></>}></Route>
          <Route exact path='*' element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </div>
    )
  };
}

const mapStateToProps = state => {
  return {
    gites: state.gites,
  }
}

export default connect(mapStateToProps)(App);
