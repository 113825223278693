import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Logo from '../img/logo.png'; import {
    CCollapse,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
    CNavItem,
} from '@coreui/react'

class NavBar2 extends Component {
    render() {
        return (
            <>
                <CNavbar expand="lg" className="navbar navbar-expand-lg bg-dark navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-0" onMouseLeave={() => this.props.leaveNavBar()}>
                    <CContainer fluid>
                        <CNavbarBrand>
                            <Link to="/">
                                <h4 className="m-0 text-uppercase text-white fs-0" >
                                    <img alt="vacansosoleil" src={Logo} atl="Logo vacansosoleil" className="fs-1 text-primary me-1 lumiere" style={{ height: 50, width: 50 }}>
                                    </img>
                                    Vacansosoleil
                                </h4>
                            </Link>
                        </CNavbarBrand>
                        <CNavbarToggler
                            className="navbar-toggler"
                            aria-label="Toggle navigation"
                            aria-expanded={this.props.bar.isActive}
                            onClick={() => this.props.openNavBar()}
                        />
                        <CCollapse className="navbar-collapse" visible={this.props.bar.isActive}>
                            <CNavbarNav className="navbar-nav ms-auto mx-lg-auto py-0">
                                <CNavItem className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}>
                                    <Link to="/" onClick={() => this.props.leaveNavBar()} active>
                                        Accueil
                                    </Link>
                                </CNavItem>
                                <CDropdown className={'nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")} popper={false}>
                                    <CDropdownToggle className={'nav-item nav-link lumiere p-0' + (this.props.bar.isActive ? "active" : "")}>Nos gîtes</CDropdownToggle>
                                    <CDropdownMenu >
                                        {Object.entries(this.props.gites.gites).map(([initial, gite]) => {
                                            const g = this.props.gites.gites[initial]
                                            return (
                                                <Link key={'gite_' + gite.id}
                                                    to={"/hebergement-a-marie-galante/" + g.nom.replace(/\W/g, '-').toLowerCase()} onClick={() => this.props.leaveNavBar()}>
                                                    <CDropdownItem href="#">{gite.nom}</CDropdownItem>
                                                </Link>
                                            )
                                        })}
                                    </CDropdownMenu>
                                </CDropdown>
                                <CNavItem className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}>
                                    <Link to="/nos-tarifs" onClick={() => this.props.leaveNavBar()}>Tarifs</Link>
                                </CNavItem>
                                <CNavItem className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}>
                                    <Link to="/nos-videos" onClick={() => this.props.leaveNavBar()}>Vidéos</Link>
                                </CNavItem>
                                <CNavItem className={'nav-item nav-link lumiere ' + (this.props.bar.isActive ? "active" : "")}>
                                    <Link to="/contact" onClick={() => this.props.leaveNavBar()}>Contact</Link>
                                </CNavItem>
                            </CNavbarNav>
                        </CCollapse>
                    </CContainer>
                </CNavbar>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        gites: state.gites,
        bar: state.bar,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        leaveNavBar: () => {
            dispatch({ type: 'LEAVE_NAVBAR' })
        },
        openNavBar: () => {
            dispatch({ type: 'OPEN_NAVBAR' })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar2);