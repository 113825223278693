import React from 'react';
import ShortGites from './ShortGites';
import { Helmet } from "react-helmet";


function PageContact(props) {
    return (
        <div className='bg-img'>
            <Helmet>
                <title>Contactez-nous pour une location</title>
            </Helmet>
            <div className="pt-4">
                <div id='contact_manuel' className="section-title position-relative text-center mx-auto pt-2 p-4 shadow mb-2 bg-body rounded py-2 " style={{ maxWidth: '600px' }}>
                    <h1 className="text-primary font-secondary">Contactez-nous</h1>
                    <h2 className="display-4 text-uppercase">Besoin d'information ?</h2>
                </div>
                <div className="container text-secondary">
                    <div className="pb-4 row">
                        <div className="row mx-auto d-flew gx-0 pt-3 pb-4 p-4 text-white bg-dark border-inner align-items-md-center mt-3 col-md-12 fs-5 text text-secondary me-2 font-weight-bol">
                            <p>Sur la page de chacun de nos gîtes, vous avez un formulaire de contact. <br /><br />
                                Si vous préférez prendre contact d'une autre manière pour la location de nos logements, voici toutes les possibilités :
                            </p>
                            <picture>
                                <source srcset="/img/contact.avif" type="image/avif" />
                                <source srcset="/img/contact.webp" type="image/webp" />
                                <img className="w-100" alt="Contactez-nous" src="/img/contact.jpg" />
                            </picture>
                        </div>
                    </div>
                    <div className="pb-4 row">
                        <ShortGites />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageContact;
