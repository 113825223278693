import { connect, useSelector } from 'react-redux';
import RangePicker from './RangePicker';
import store from '../app/store';
import BasicModal from './BasicModal';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from './Toast';

function Contact(props) {
    const montant_sejour = useSelector((state) => state.contact.montant_sejour);
    const gites = useSelector((state) => state.gites.gites);
    const contact = useSelector((state) => state.contact);
    const selectNbPersonnes = React.useRef()

    useEffect(() => {
        selectNbPersonnes.current.classList.replace('is-valid', 'is-invalid')
        selectNbPersonnes.current.value = "Selectionner"
        props.updateFormDate('setNbPersonne', "Selectionner");
        props.updateFormDate('setInitial', props.initial);
        props.updateFormDate("setGites", store.getState().gites.gites[props.initial].nom)
        props.updateFormDate("setLieu", store.getState().gites.gites[props.initial].lieu)
        props.calculTarif(store.getState().gites.gites[props.initial].tarif)

    }, [props.initial])

    function textNotNul(event, action) {
        if (action !== 'setMail') {
            if ((event.target.value !== "") && (event.target.value !== "Selectionner") && (event.target.classList)) {
                event.target.classList.replace('is-invalid', 'is-valid');
            } else {
                event.target.classList.replace('is-valid', 'is-invalid');
            }
        } else {
            isEmail(event)
        }

        var intpuValie = event.target.value
        if ((!isNaN(parseInt(event.target.value))) && (action !== 'setTelephone') && (action !== 'setAdresse')) {
            intpuValie = parseInt(event.target.value)
        }

        props.updateFormDate("setGites", store.getState().gites.gites[props.initial].nom)
        props.updateFormDate(action, intpuValie, props.initial)
        props.calculTarif(store.getState().gites.gites[props.initial].tarif)
    }

    function isEmail(event) {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regEmail.test(event.target.value)) {
            event.target.classList.replace('is-invalid', 'is-valid');
        } else {
            event.target.classList.replace('is-valid', 'is-invalid');
        }
    }


    function nombrePersonne() {
        return (
            Array.from(Array(gites[props.initial].maxclient), (e, i) => {
                return <option key={"personne_" + i} value={i + 1}>{i + 1}</option>
            })
        )
    }

    function contactMode() {
        return (
            Array.from(["Pas de préférence", "Téléphone", "E-mail"], (e, i) => {
                return <option key={"contact_" + i} >{e}</option>
            })
        )
    }


    return (
        <>
            <BasicModal />
            <form className='form-control border-0 pt-2' autoComplete={true}>
                <h5>N'hésitez pas a nous contacter, nous vous répondrons rapidement.</h5>
                <div className='row justify-content-around'>
                    <div className="col-sm-6">
                        <label >Dates</label>
                        <RangePicker key={"picker"} id="picker" initial={props.initial} ></RangePicker>
                    </div>

                    <div className="col-sm-6">
                        <label htmlFor="floatingInput">Mode de contact</label>
                        <select type="text" key={"contactmode"}
                            id="contactmode"
                            className='form-control is-invalid'
                            style={{ height: '55px' }}
                            onLoad={(e) => textNotNul(e, 'setContactMode')}
                            onChange={(e) => textNotNul(e, 'setContactMode')}
                            defaultValue="Selectionner"
                            required={true}  >
                            <option>Selectionner</option>
                            {contactMode()}
                        </select>
                    </div>
                </div>

                <div className='row justify-content-around'>
                    <div className="col-sm-6">
                        <label >Votre nom</label>
                        <input type="text"
                            key={"nom"} id="nom"
                            className='form-control is-invalid'
                            style={{ height: '55px' }}
                            onLoad={(e) => textNotNul(e, 'setNom')}
                            onChange={(e) => textNotNul(e, 'setNom')}
                            defaultValue={contact.client_nom}
                            required={true}
                        />
                    </div>

                    <div className="col-sm-6">
                        <label>Votre prénom</label>
                        <input type="text"
                            key={"prenom"}
                            id="prenom"
                            className='form-control is-invalid'
                            style={{ height: '55px' }}
                            onLoad={(e) => textNotNul(e, 'setPrenom')}
                            onChange={(e) => textNotNul(e, 'setPrenom')}
                            defaultValue={contact.client_prenom}
                            required={true} />
                    </div>
                </div>

                <div className='row justify-content-around'>
                    <div className="col-sm-6">
                        <label >Votre e-mail</label>
                        <input type="email"
                            key={"email"} id="email"
                            className={'form-control is-invalid'}
                            style={{ height: '55px' }}
                            onLoad={(e) => textNotNul(e, 'setMail')}
                            onChange={(e) => textNotNul(e, 'setMail')}
                            defaultValue={contact.client_mail}
                            required={true} />
                    </div>

                    <div className="col-sm-6">
                        <label htmlFor="floatingInputNbPersonne">Nombre de personnes</label>
                        <select type="text"
                            key={"nbpersonne"} id="nbpersonne"
                            className='form-control is-invalid'
                            style={{ height: '55px' }}
                            onLoad={(e) => textNotNul(e, 'setNbPersonne')}
                            onChange={(e) => textNotNul(e, 'setNbPersonne')}
                            onBlur={(e) => textNotNul(e, 'setNbPersonne')}
                            ref={selectNbPersonnes}
                            defaultValue="Selectionner">
                            <option>Selectionner</option>
                            {nombrePersonne()}
                        </select>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-sm-6">
                        <label >Votre numéro de téléphone</label>
                        <input type="numeric"
                            key={"numtel"} id="numtel"
                            className='mt-1 form-control is-invalid'
                            style={{ height: '55px' }}
                            onLoad={(e) => textNotNul(e, 'setTelephone')}
                            onChange={(e) => textNotNul(e, 'setTelephone')}

                            defaultValue={contact.client_telephone} />
                    </div>

                    <div className="col-sm-6">
                        <label>Votre adresse</label>
                        <textarea key={"adresse"} id="adresse" className="form-control bg-light border-0 px-4 py-3" rows="3" onChange={(e) => textNotNul(e, 'setAdresse')} placeholder="Votre adresse (facultative, nous permet de créer un contrat rapidement)"></textarea>
                    </div>
                </div>

                <label className='mt-2' id="tarif"><b>Solicitation pour: {gites[props.initial].nom} (prix: {montant_sejour}€)</b></label>

                <div className="col-sm-12 mt-2">
                    <input key={"sujet_" + gites[props.initial].id} id="sujet" type="text" className="form-control bg-light border-0 px-4" onChange={(e) => textNotNul(e, 'setSujet')} placeholder="Sujet" defaultValue={contact.mail_sujet} style={{ height: '55px' }} />
                </div>
                <div className="col-sm-12 mt-2">
                    <textarea key={"message"} id="message" className="form-control bg-light border-0 px-4 py-3" rows="6" onChange={(e) => textNotNul(e, 'setMessage')} placeholder="Votre message" required={true}></textarea>
                </div>
                {/* <div className="cf-turnstile" data-sitekey="0x4AAAAAAAY9GsCpIIpfwlsK" data-callback="javascriptCallback"></div> */}
                <div className="col-sm-12 mt-3">
                    <button id='envoyer' className="btn btn-primary border-inner w-100 py-3" type="submit" onClick={(e) => callAPI(e, props)}>Envoyer</button>
                </div>
            </form>
        </>
    );
}

const mapStateToProps = state => {
    return {
        gites: state.gites,
        contact: state.contact,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateFormDate: (type, value, initial) => {
            dispatch({ type: type, payload: value, initial: initial })
        },
        calculTarif: (giteTarif) => {
            dispatch({ type: 'calcul', tarif: giteTarif })
        },
    }
}

const callAPI = (e, props) => {
    e.preventDefault();
    var contact = store.getState().contact
    if (contact.client_mail == "") {
        return Toast("E-mail manquant")
    }

    if (contact.client_nom == "") {
        return Toast("Votre nom est manquant")
    }

    if (contact.client_prenom == "") {
        return Toast("Votre prénom est manquant")
    }

    props.updateFormDate("mailValidator", true)
    const body = JSON.stringify(store.getState().contact)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body,
    };
    fetch(window.location.origin + '/api', requestOptions)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            props.updateFormDate('returnValue', data)
        })
}


export default connect(mapStateToProps, mapDispatchToProps)(Contact);


