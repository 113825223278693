import { Component } from 'react';
import { connect } from 'react-redux';
import Gallery from './Gallery';
import GalleryConfig from '../assets/gallery.json';
import Maps from './Maps';
import Contact from './Contact';
import { Helmet } from "react-helmet";


class PageNosVideos extends Component {
  render() {
    const gite = this.props.gites.gites[this.props.initial]
    const rootGite = this.props.gites.important
    const initial = this.props.initial
    const contact = () => {
      return <Contact initial={initial} />
    }

    return (

      <div className='bg-img'>
        <div className="pt-4">
          <div id={gite.initial} className="section-title position-relative text-center mx-auto  pt-2 p-4 shadow mb-2 bg-body rounded py-2 " style={{ maxWidth: '600px' }}>
            <h1 className="text-primary font-secondary">Louer "{gite.nom}"</h1>
            <h2 className="display-4 text-uppercase">A partir de {gite.tarif}€ !</h2>
            <Helmet>
              <title>Le gîte: {gite.nom} de Marie-Galante</title>
            </Helmet>
          </div>
          <div className="container-flex text-secondary py-2" style={{ marginTop: '20px' }}>
            <div className="container">
              <div className="row gx-4 pt-2 pb-4 p-3 bg-primary border-inner align-items-md-center">
                <div className="d-flex align-items-sm-center text-left p-3 pb-4">
                  <div className='row col-lg-12'>
                    <p className="mt-1 bg-dark border-inner p-4 m-1 pb-1"> {gite.description.description.map((desc, index) => {
                      return (
                        <p className="fs-5 text text-secondary me-2 font-weight-bol"><b>{desc}</b></p>
                      )
                    })}
                    </p>
                    <div className='row p-4 col-lg-7 pt-5 mx-auto' >
                      <ul>
                        <p className="mt-1"> {gite.description.listDescription.map((desc, index) => {
                          return (
                            <li key={"descr_" + gite.id + '_' + index} className="fs-5 text text-secondary me-2 font-weight-bol"><b>{desc}</b><br /></li>
                          )
                        })}
                        </p>
                      </ul>
                    </div>
                    <div className="row mx-auto d-flew pb-4 p-2 pt-4 bg-dark border-inner align-items-md-center align-self-center mt-3 col-lg-5">
                      {gite.important.map((info, idx) => {
                        const icon = rootGite[info.type].icon
                        const message = rootGite[info.type].message
                        return (
                          <div key={"importante_div_" + gite.id + "_" + idx} className="col d-flex justify-content-center">
                            <div key={"importante_bg_" + gite.id + "_" + idx} className="bg-dark text-primary m-1 text-center p-1">
                              <img loading="lazy" alt={"Gite: " + gite.nom} src={icon} style={{ width: "40px", height: "40px" }} />
                            </div>
                            <div key={"importante_11_" + gite.id + "_" + idx} className="d-flex flex-column justify-content-center text-start text-center m-0 p-2">
                              <p key={"importante_10_" + gite.id + "_" + idx} className="text-white " style={{ width: "150px", height: "40px" }}>{info.message !== "" ? info.message : message}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className='gx-0 mt-4' id={"photos-" + gite.url_folder}>
                  <Gallery images={GalleryConfig[gite.initial]} />
                </div>
              </div>

              <div id={"map-" + gite.url_folder}>
                <div className="row gx-4 pt-5 pb-4 p-2 bg-dark border-inner align-items-md-center mt-3">
                  <div className="d-flex mb-2 justify-content-center text-center">
                    <img className="img-fluid" src="/img/gps.png" alt="localisation des gites" style={{ width: '60px', height: '60px', objectFit: 'cover' }} />
                    <h4 className="text-uppercase my-2">Emplacement de l'hébergement</h4>
                  </div>
                  <div className="d-flex justify-content-center text-center mb-2">
                    <span>69, Boulevard maritime <br /> 97112 Grand-Bourg <br />Marie-Galante</span>
                  </div>
                  <Maps style={{ zIndex: 2 }} className="overlay" ref="childDiv" initial={this.props.initial}></Maps>
                </div>
              </div>

              <div className="col-md-12 mt-3 pb-4">
                <div className="row gx-4 pt-3 pb-4 p-3 bg-primary border-inner align-items-md-center mt-3">
                  {contact()}
                  <div className="row section-title"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    popup: state.popup,
    home: state.home,
    gites: state.gites,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeStatus: () => {
      dispatch({ type: 'view' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageNosVideos);
