import { configureStore } from '@reduxjs/toolkit';
import accueilPopup from './features/accueilPopup';
import homePages from './features/homePages';
import nosGites from './features/nosGites';
import contactForm from './features/contactForm';
import mailValidator from './features/mailValidator';
import nosVideos from './features/nosVideos';
import navBar from './features/navBar';

export default configureStore({
  reducer: {
    popup: accueilPopup,
    mail: mailValidator,
    home: homePages,
    gites: nosGites,
    contact: contactForm,
    videos: nosVideos,
    bar: navBar,
  },
})
